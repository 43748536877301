.tooltip {
  cursor: pointer;
}

.number-of-users {
  dominant-baseline: central;
  text-anchor: middle;
  fill: #fff;
}

.tooltip-btn-container {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}
