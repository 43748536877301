.tech-list {
  display: block;
  width: 30rem;
  float: right;
  margin: 5px;
  height: 700px;
  overflow-y: auto;

  @media screen and (max-width: 1650px) {
    width: 19rem;
  }
}
