.description {
  border-top: 1px solid #777;
  color: #333;
  font-size: 16px;
  font-weight: 100;
  margin: 0;
  padding: 20px;
}

.tags {
  border-top: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1.5px solid #777;
  color: #333;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  p {
    margin-right: 5px;
    word-wrap: break-word;
  }
}

.contacts {
  border-bottom: 1px solid #777;
  border-top: 1px solid #777;
  color: #333;
  font-size: 16px;
  font-weight: 100;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-flow: column;

  p {
    border-bottom: 1px solid #ee0b77;
    color: #ee0b77;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
  }
}
