.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  background-color: #fefefe;
  padding: 20px;
  border-radius: 0.5rem;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;

    &-title {
      display: flex;
      flex-flow: row;
      align-items: center;
      width: 100%;

      .name {
        margin-right: 1rem;
      }
    }
  }

  .btn-close {
    margin-top: -30px;
    margin-right: -20px;
    padding: 0.5rem;
    color: #8b94b5;

    &:hover {
      color: #6f7690;
      background-color: transparent;
    }
  }

  .modal-body {
    padding: 1rem;
  }
}
