.header {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 43, 159, 0.04);
  width: 100%;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 50px;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 28px;
  height: 32px;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
  font-size: 16px;
  text-transform: uppercase;
  color: #585e74;
  text-align: center;
}

.header-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 10px;
}
