.main {
  padding: 24px;
  width: 100%;

  @media (min-width: 1200px) and (max-width: 1500px) {
    display: flex;
  }
}

.flex {
  display: flex;
  flex-direction: column;
}

.section {
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 80vh;
}

.section-list {
  position: relative;
  display: grid;
  grid-template-columns: 4.6fr 1fr;
  justify-content: flex-start;
  gap: 10px;
  padding: 16px 26px;

  @media (min-width: 1200px) and (max-width: 1550px) {
    grid-template-columns: 4.6fr 2fr;
  }
}

.section-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: -120px;
}

.section-no-radars {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  text-transform: uppercase;
  font-size: 20px;
  color: #585e74;
}

.section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 26px;

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #585e74;
}

.nix-tech-radar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 26px;
}

.horizontal-line {
  border-bottom: 1px solid #d2cfcf;
  margint: 10px 10px;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  padding: 16px 26px;
}

.list-item {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  color: #313440;
  cursor: pointer;
  padding: 1rem 3rem;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 10px;
  width: 25rem;
  height: 5rem;
  gap: 2rem;
  transition: 0.2s all;

  &:hover {
    transform: scale(1.03);
  }

  img {
    width: 3rem;
    height: 3rem;
  }
}

.section-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.page-not-found {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  gap: 20px;

  .status {
    font-weight: 100;
    font-size: 200px;
    letter-spacing: 1rem;
    color: #585e74;
  }

  .message {
    font-size: 18px;
  }
}
