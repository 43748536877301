.switch-btn-container {
  position: absolute;
  top: 50%;
  right: 65px;
  transform: translate(0, -50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
  cursor: pointer;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
  &.css-1xnbq41-MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding-right: 130px !important;
    }
  }
}

.switch-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 6px;
  height: 100%;
  border: none;
  color: #000;
  font-size: 10px;
  background-color: #fff;
  transition: background-color 0.5s;
  cursor: pointer;

  &.active {
    background-color: #1976d2;
    position: relative;
    z-index: 1;
    color: #fff;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1976d2;
      opacity: 0;
      transition:
        ease-out 0.5s,
        transform 0.5s;
      transform: translateX(-100%);
      z-index: -1;
    }
  }

  .active + .active:before {
    opacity: 1;
    transform: translateX(0);
  }
}
