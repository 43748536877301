.footer-info {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
  bottom: 0;
  padding-top: 20px;
}

.footer-info-right {
  font-family: inherit;
  font-weight: 400;
  line-height: 16px;
  color: #585e74;
  text-align: end;
}

.footer-link {
  text-decoration: underline;
  color: #4b96fe;
  cursor: pointer;
}
