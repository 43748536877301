.search {
  .MuiOutlinedInput-notchedOutline {
    border-color: #dbdfec !important;
  }

  .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #585e74 !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #585e74 !important;
    border-width: 1px !important;
    box-shadow: none;
    outline: none;
  }

  .search-icon {
    width: 28px;
    height: 28px;
    padding: 4px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
    }
  }
}

.search-field {
  width: 100%;
  #combo-box-demo.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.MuiAutocomplete-inputFocused.MuiAutocomplete-input {
    flex-grow: 0.6;
  }

  @media (min-width: 1200px) and (max-width: 1550px) {
    #combo-box-demo.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.MuiAutocomplete-inputFocused.MuiAutocomplete-input {
      flex-grow: 0.7;
    }
  }
}
