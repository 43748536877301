.searchList {
  position: absolute;
  border: 1px solid rgba(223, 218, 218, 0.87);
  border-radius: 10px;
  max-height: 200px;
  width: 100%;
  overflow: scroll;
  margin: 120px 0 0 0;
  background-color: white;
  &_notFound {
    padding: 10px;
    color: #7f7f81;
  }
}
