.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 32px;
  height: 32px;
  border: 1px solid #dbdfec;
  background-color: #fff;
  border-radius: 7px;
  cursor: pointer;

  &:hover {
    border: 1px solid #a8aec5;
    box-shadow: 0 0 0 1px #dbdfec;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btn-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 100px;
}

.btn-category {
  &.inactive {
    background-color: #a8aec5;
    opacity: 0.5;
  }
}

.btn-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1b94c4;
  text-decoration: underline;
  cursor: pointer;
}
