.tech-item {
  border-radius: 2px;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 5px;
}

.highlight {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
