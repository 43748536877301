.legend {
  position: absolute;
  right: 40%;
  width: fit-content;

  svg {
    margin-right: 5px;
  }
}

.legend-item {
  display: flex;
  align-items: center;
}
