.ring-name {
  margin-bottom: 15px;
}

.tech-items {
  margin-left: 10px;

  &:last-child {
    margin-bottom: 10px;
  }
}
