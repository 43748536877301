*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*:active,
*:focus {
  font-family: 'Open Sans', sans-serif;
  outline: none;
}

html,
body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  background-color: #f4f8ff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
